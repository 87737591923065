import React from 'react';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { X } from 'react-bootstrap-icons';
import { MONTH_OPTS, STOCK_STATUS_OPTS, FORMAT_OPTS } from '../../constants';
import _range from 'lodash/range';
import { DateTime } from 'luxon';

class AdvancedSearchForm extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            isIsbnSearch: false
        }
        var d = new Date();
        var year = d.getFullYear();
        this.yearOpts = _range(2000, year + 1);
    }
    
    checkDates = (v, id, formikBag) => {
      let newValues = Object.assign({}, formikBag.values, {[id]: v});
      if (newValues.publishDateFromMonth && newValues.publishDateFromYear && newValues.publishDateToMonth && newValues.publishDateToYear) {
        // console.log("we have all the values");
        let fromDate = DateTime.fromISO(`${newValues.publishDateFromYear}-${newValues.publishDateFromMonth}`);
        let toDate = DateTime.fromISO(`${newValues.publishDateToYear}-${newValues.publishDateToMonth}`);
        if (fromDate > toDate) {
          // console.log("we have a problem", newValues);
          if (newValues.publishDateToYear.toString() === this.yearOpts[0].toString() && newValues.publishDateToMonth === "01") {
            // console.log("the toDate is the min");
            formikBag.setFieldValue("publishDateToMonth", "02");
            formikBag.setFieldValue("publishDateFromMonth", "01");
            formikBag.setFieldValue("publishDateFromYear", this.yearOpts[0]);
          } else if (newValues.publishDateFromYear.toString() === this.yearOpts[this.yearOpts.length - 1].toString() && newValues.publishDateFromMonth === "12") {
            // console.log("the fromDate is the max");
            formikBag.setFieldValue("publishDateFromMonth", "11");
            formikBag.setFieldValue("publishDateToMonth", "12");
            formikBag.setFieldValue("publishDateToYear", this.yearOpts[this.yearOpts.length - 1]);
          } else {
            if (id === "publishDateFromYear" || id === "publishDateFromMonth") {
              // console.log("change the toDate");
              let newToDate = fromDate.plus({months: 1});
              formikBag.setFieldValue("publishDateToYear", newToDate.toFormat("yyyy"));
              formikBag.setFieldValue("publishDateToMonth", newToDate.toFormat("MM"));
            }
            if (id === "publishDateToYear" || id === "publishDateToMonth") {
              // console.log("change the fromDate");
              let newFromDate = toDate.minus({months: 1});
              formikBag.setFieldValue("publishDateFromYear", newFromDate.toFormat("yyyy"));
              formikBag.setFieldValue("publishDateFromMonth", newFromDate.toFormat("MM"));
            }
          }
        }
      }
    }

    render() {
        return (
            <Formik onSubmit={this.props.onSubmit} initialValues={this.props.values || {}} enableReinitialize>
                {(formikBag) => (
                    <Form>
                      <FormField id="isbn" label="ISBN" formikBag={formikBag} onChange={(v) => { this.setState({isIsbnSearch: Boolean(v)}); }} />
                      <div className="my-4 d-flex justify-content-center align-items-center bg-secondary" style={{height: "2px"}}><span className="px-2 bg-white">OR</span></div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                            <FormField id="title" label="Title" formikBag={formikBag} disabled={this.state.isIsbnSearch} />
                            <FormField id="authorName" label="Author" formikBag={formikBag} disabled={this.state.isIsbnSearch} />
                            {/*<FormField id="keyword" label="Keyword" formikBag={formikBag} disabled={this.state.isIsbnSearch} />*/}
                            {/*<FormField id="bicCode"
                                label="Subject"
                                type="select"
                                options={BICCODE_OPTS}
                                formikBag={formikBag}
                                disabled={this.state.isIsbnSearch}
                            />*/}
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <FormField id="format"
                                    label="Format"
                                    type="select"
                                    options={FORMAT_OPTS}
                                    formikBag={formikBag}
                                    disabled={this.state.isIsbnSearch}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <FormField id="instock"
                                    label="Stock Status"
                                    type="select"
                                    options={STOCK_STATUS_OPTS}
                                    formikBag={formikBag}
                                    disabled={this.state.isIsbnSearch}
                                />
                              </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Publish date from</label>
                                        <div className="row mx-n1">
                                            <div className="col px-1">
                                                <FormControl
                                                    as="select"
                                                    value={formikBag.values.publishDateFromMonth || ""}
                                                    disabled={this.state.isIsbnSearch}
                                                    onBlur={() => { formikBag.setFieldTouched("publishDateFromMonth"); }}
                                                    onChange={(e) => {
                                                        let v = e.target.value;
                                                        formikBag.setFieldValue("publishDateFromMonth", v);
                                                        this.checkDates(v, "publishDateFromMonth", formikBag);
                                                    }}
                                                >
                                                    <option disabled={Boolean(formikBag.values.publishDateFromMonth)} value="">Month</option>
                                                    {MONTH_OPTS.map((opt, i) => {
                                                        return (<option value={opt.value} key={i}>{opt.label}</option>);
                                                    })}
                                                </FormControl>
                                            </div>
                                            <div className="col px-1">
                                                <FormControl
                                                    as="select"
                                                    value={formikBag.values.publishDateFromYear || ""}
                                                    disabled={this.state.isIsbnSearch}
                                                    onBlur={() => { formikBag.setFieldTouched("publishDateFromYear"); }}
                                                    onChange={(e) => {
                                                        let v = e.target.value;
                                                        formikBag.setFieldValue("publishDateFromYear", v);
                                                        this.checkDates(v, "publishDateFromYear", formikBag);
                                                    }}
                                                >
                                                    <option disabled={Boolean(formikBag.values.publishDateFromYear)} value="">Year</option>
                                                    {this.yearOpts.map((opt, i) => (<option value={opt} key={i}>{opt}</option>) )}
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Publish date to</label>
                                        <div className="row mx-n1">
                                            <div className="col px-1">
                                                <FormControl
                                                    as="select"
                                                    value={formikBag.values.publishDateToMonth || ""}
                                                    disabled={this.state.isIsbnSearch}
                                                    onBlur={() => { formikBag.setFieldTouched("publishDateToMonth"); }}
                                                    onChange={(e) => {
                                                        let v = e.target.value;
                                                        formikBag.setFieldValue("publishDateToMonth", v);
                                                        this.checkDates(v, "publishDateToMonth", formikBag);
                                                    }}
                                                >
                                                    <option disabled={Boolean(formikBag.values.publishDateToMonth)} value="">Month</option>
                                                    {MONTH_OPTS.map((opt, i) => {
                                                        return (<option value={opt.value} key={i}>{opt.label}</option>);
                                                    })}
                                                </FormControl>
                                            </div>
                                            <div className="col px-1">
                                                <FormControl
                                                    as="select"
                                                    value={formikBag.values.publishDateToYear || ""}
                                                    disabled={this.state.isIsbnSearch}
                                                    onBlur={() => { formikBag.setFieldTouched("publishDateToYear"); }}
                                                    onChange={(e) => {
                                                        let v = e.target.value;
                                                        formikBag.setFieldValue("publishDateToYear", v);
                                                        this.checkDates(v, "publishDateToYear", formikBag);
                                                    }}
                                                >
                                                    <option disabled={Boolean(formikBag.values.publishDateToYear)} value="">Year</option>
                                                    {this.yearOpts.map((opt, i) => (<option value={opt} key={i}>{opt}</option>) )}
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-end mt-3">
                                <Button type="reset" variant="link" onClick={()=>{ this.setState({isIsbnSearch: false}); this.props.onClear(); }}><X size={24} /> Clear Search</Button>
                                <Button type="submit" variant="primary" className="ms-3">Search</Button>
                            </div>
                        </div>
                      </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default AdvancedSearchForm;
