import { request } from '../functions/apiRequestWrapper';
import { logout } from './User';

const SET_LIST = "RES/SET_LIST";
const CLEAR_LIST = "RES/CLEAR_LIST";
const SET_PAGE = "RES/SET_PAGE";
const SET_SORT = "RES/SET_SORT";
const SET_LOADING = "RES/SET_LOADING";
const SET_ERROR = "RES/SET_ERROR";
const SET_SEARCH = "RES/SET_SEARCH";

export function getList(_page, _orderBy, _advSearch) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        const resources = getState().resources;
        const user = getState().user;
        let country = user.data && user.data.country ? user.data.country : "AU";
        // let summaryField = country === "AU" ? "ProductSummary" : "ProductSummaryNZ";
        let sortField = resources.sortField;
        if (sortField === "publishDate" && country === "NZ") { sortField = "PublishDateNZ"; }
        let currentPage = resources.page;
        if (_page && typeof _page !== "undefined") {
            currentPage = _page;
            dispatch({ type: SET_PAGE, payload: _page });
        }
        let skip = currentPage > 1 ? resources.pageSize * (currentPage - 1) : 0;
        let orderBy = _orderBy || `${sortField} ${resources.sortDir}`; // "publishDate desc";
        let q = `?$top=${resources.pageSize}&$skip=${skip}&$count=true&$orderby=${orderBy}`;

        if (resources.search) {
            let s = resources.search.replace(/['‘’]/g, "''");
            let authorSearch = '(';
            let splitSearch = s.split(" ");
            for (let n = 0; n < splitSearch.length; n++) {
                authorSearch += (`contains(AuthorName, '${encodeURIComponent(splitSearch[n])}') `);
                if (n < splitSearch.length - 1) {
                  authorSearch += " and ";
                }
            }
            authorSearch += ')';
            s = encodeURIComponent(s);
            //let f = `&$filter=(InStock eq 'In Stock' or InStock eq 'Print On Demand') and (contains(ISBN, '${s}') or contains(Title, '${s}') or ${authorSearch} or contains(${summaryField}, '${s}'))`;
            let f = `&$filter=(ISBN eq '${s}' or contains(Title, '${s}') or ${authorSearch})`;
            // console.log("filter: ", f);
            q = q.concat(f);

        }
        request(
            `${process.env.REACT_APP_API}/1/product/resources${q}`
        ).then((data) => {
            if (data.items.length === 0) {
                dispatch({ type: SET_ERROR, payload: "No results found" });
            } else {
                let items = data.items?.map(item => {
                  let rData = JSON.parse(item.resourceData);
                  item.resourceData = rData;
                  return item;
                });
                dispatch({ type: SET_LIST, payload: items, count: data.count });
            }
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
        });
    }
}

export function setSort(field, dir) {
    return (dispatch, getState) => {
        dispatch({ type: SET_SORT, field, dir });
        dispatch(getList(undefined, `${field} ${dir}`));
    }
}

export function setSearch(s) {
    return { type: SET_SEARCH, payload: s };
}

export function clearList() {
    return { type: CLEAR_LIST };
}

const defaultState = {
    list: [],
    count: 0,
    page: 1,
    pageSize: 20,
    sortField: "publishDate", // "title", "authorName"
    sortDir: "desc", // "asc"
    isLoading: false,
    error: null,
    search: "", // simple search field value
};

export function resourcesReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LIST:
            return Object.assign({}, state, {
                list: action.payload,
                count: action.count,
                isLoading: false,
                error: null
            });
        case CLEAR_LIST:
            return Object.assign({}, state, {
                list: [],
                count: 0,
                page: 1,
                error: null
            });
        case SET_PAGE:
            return Object.assign({}, state, {
                page: action.payload
            });
        case SET_SORT:
            return Object.assign({}, state, {
                sortField: action.field || state.sortField,
                sortDir: action.dir || state.sortDir
            });
        case SET_LOADING:
            return Object.assign({}, state, {
                isLoading: action.payload
            });
        case SET_ERROR:
            return Object.assign({}, state, {
                error: action.payload,
                list: [],
                isLoading: false
            });
        case SET_SEARCH:
            return Object.assign({}, state, {
                search: action.payload
            });

        default:
            return state;
    }
}
