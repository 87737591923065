import Button from 'react-bootstrap/Button';
import { useRef, useState, useEffect } from "react";
import classnames from 'classnames';
import { X, Cart } from 'react-bootstrap-icons';
import './book-cart.scss';

import { addToCart } from '../../redux/Cart';
import { connect } from 'react-redux';
const mapStateToProps = (state, ownProps) => {
    return {
        isLoadingCart: state.cart.isLoadingCart,
        error: state.cart.error,
        currentCartItem: state.cart.currentCart ? state.cart.currentCart.items.find(x => x.productId === ownProps.isbn) : {},
    };
}
const mapDispatchToProps = dispatch => {
    return {
        addToCart: (item, qty) => { dispatch(addToCart(item, qty)); }
    };
}


const BookCart = ({ isbn, price, title, imageUrl, isLoadingCart, addToCart, currentCartItem, appendKey, alwaysShowBtn, btnSize, className }) => {
    const qtyRef = useRef();
    const useAppendKey = appendKey ? '-' + appendKey : '';

    var cartQty = (currentCartItem && currentCartItem.productCount ? currentCartItem.productCount : 0);
    const [qty, setQty] = useState(cartQty);
    const [dataQty, setDataQty] = useState(cartQty);

    let newCartItem = {
        productId: isbn,
        unitPrice: price,
        product: {
            name: title,
            productImageUrlLarg: ""
        },
        imageUrl: imageUrl
    };

    const handleKeyDown = (e) => {
        if (e.code === 'Enter') { addToCart(newCartItem, qtyRef.current.value) } //update value on enter
        if (e.code === 'Minus') { e.preventDefault() } //setting the input with a min of 0 does not stop user typing negative numbers. this does.
    }

    //update the qty when currentCartItem changes. this handles a book being on a page twice, which happens with the release book carousel
    useEffect(() => {
        var cartQty = (currentCartItem && currentCartItem.productCount ? currentCartItem.productCount : 0);
        var isEdited = (qty !== dataQty);
        if(!isEdited) {
            //we only set the current displayed qty from server data if the user is not currently editing it
            setQty(cartQty);
        }
        setDataQty(cartQty);
    }, [currentCartItem, dataQty, qty]);

    return (
        <div className={classnames("book-cart input-group input-group-sm flex-nowrap", className)}>
            <span className="input-group-text"><Cart size={14} /></span>
            <input
                className={`form-control qty ${(qty === dataQty && qty === 0) ? 'greyed' : ''}`}
                ref={qtyRef}
                key={`${isbn}-cart${useAppendKey}`}
                value={isLoadingCart ? '' : qty}
                disabled={isLoadingCart}
                type="number"
                min="0"
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={e => setQty(Number(e.target.value))}
                onFocus={(e) => { e.target.select(); }}
            />
            {(qty !== dataQty || alwaysShowBtn) &&
                <>
                    {!isLoadingCart && (qty !== dataQty) &&
                        <span className="input-group-text cancel-wrapper">
                            <button className="cancel-button" onClick={() => { setQty(Number(dataQty)) }}><X size={16} /></button>
                        </span>
                    }
                    <Button
                        size={btnSize || "sm"}
                        onClick={() => addToCart(newCartItem, qtyRef.current.value)}
                        disabled={isLoadingCart || (qty === dataQty)}>
                        {(qty === 0 && dataQty !== 0) ? 'Remove' : (dataQty === 0) ? 'Add' : 'Update'}
                    </Button>
                </>
            }
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BookCart)
