import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import Logo from '../Logo';
import { CatalogueSearchField } from '../search/SearchField';
import { Person, BoxArrowRight, Cart } from 'react-bootstrap-icons';
import Incognito from '../Incognito';
import './header.scss';
import {
    PATH_HOME,
    PATH_RELEASES,
    PATH_CATALOGUE,
    PATH_RESOURCES,
    PATH_OFFERS,
    PATH_NEWS,
    PATH_ACCOUNT, 
    PATH_CART,
    PATH_SAVED_ORDERS,
    PATH_ACT_AS_USER,
    PATH_INVOICES,
    FEATURE_INVOICES,
    DIGITAL_ASSETS_URL
} from '../../constants';

import { connect } from 'react-redux';
import { logout } from '../../redux/User';
import { triggerSearch } from '../../redux/Catalogue';
const mapStateToProps = state => {
    return {
        userData: state.user.data,
        isLoadingCart: state.cart.isLoadingCart,
        cartTotal: state.cart.currentCart && state.cart.currentCart.totalAmount ? state.cart.currentCart.totalAmount : 0,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); },
        triggerSearch: () => { dispatch(triggerSearch()); }
    }
}

const Header = (props) => {

    const [ expanded, setExpanded ] = useState(false);

    return (<>
        <div id="account-header" className="bg-dark text-light">
            <div className="container px-3 px-md-5">
                <div className="row justify-content-end py-2">
                    {props.userData && <>

                        <div className="col-auto ps-3">
                            {!props.isLoadingCart &&
                                <Link to={PATH_CART} className="text-light">
                                    <Cart size={18} style={{ verticalAlign: "top" }} /> ${props.cartTotal}
                                </Link>
                            }
                        </div>
                        {props.userData.canActAsUser && <div className="col-auto ps-3">
                            {!props.userData.actingAsUser &&
                                <Link to={PATH_ACT_AS_USER}><Incognito size={18} /> Act as user</Link>
                            }
                            {props.userData.actingAsUser &&
                                <Link to={PATH_ACT_AS_USER} className="text-warning"><Incognito size={18} /> Acting as <span className="d-none d-lg-inline">{props.userData.actingAsUser.firstName} {props.userData.actingAsUser.lastName} (</span>{props.userData.actingAsUser.accountId}<span class="d-none d-lg-inline">)</span></Link>
                            }
                        </div>}
                        <div className="col-auto">
                            <Dropdown align="end" onSelect={(ek) => { if (ek === "logout") { props.logout(); } }}>
                                <Dropdown.Toggle variant="link" id="account-nav-toggle">
                                    <Person size={21} style={{verticalAlign: "top"}} /> {props.userData.firstName} {props.userData.lastName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="text-end">
                                    <Link to={PATH_ACCOUNT} className="dropdown-item">Account</Link>
                                    <Link to={PATH_SAVED_ORDERS} className="dropdown-item">Saved Orders</Link>
                                    {FEATURE_INVOICES && <Link to={PATH_INVOICES} className="dropdown-item">HEDS Invoices and Statements</Link>}
                                    <Dropdown.Divider />
                                    <Dropdown.Item eventKey="logout">Logout <BoxArrowRight size={20} style={{verticalAlign: "top"}} /></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>}
                </div>
            </div>
        </div>

        <div className="container px-3 px-md-5">
            <Navbar expand="lg" expanded={expanded} onToggle={(e) => {setExpanded(e);}} className="px-0 py-3" id="main-header">
                <Link to={PATH_HOME} className="navbar-brand order-0"><Logo /></Link>
                <Navbar.Toggle aria-controls="main-nav" className="ms-auto me-3 order-1" />
                <Navbar.Collapse id="main-nav" className="order-4 order-lg-3">
                    <Nav className="ms-auto">
                        <NavLink to={PATH_RELEASES} onClick={() => {setExpanded(false);}} className="nav-link">Releases</NavLink>
                        <NavLink to={PATH_CATALOGUE} onClick={() => {setExpanded(false);}} className="nav-link">Catalogue</NavLink>
                        {props.userData.country === "AU" && <a href={DIGITAL_ASSETS_URL} target="_blank" rel="noreferrer" className="nav-link">Digital Assets</a>}
                        <NavLink to={PATH_OFFERS} onClick={() => {setExpanded(false);}} className="nav-link">Offers</NavLink>
                        <NavLink to={PATH_NEWS} onClick={() => {setExpanded(false);}} className="nav-link">News</NavLink>
                        <NavLink to={PATH_RESOURCES} onClick={() =>{setExpanded(false);}} className="nav-link">Resources</NavLink>
                        <NavLink to={PATH_ACCOUNT} className="nav-link">Account</NavLink>
                    </Nav>
                </Navbar.Collapse>
                <CatalogueSearchField
                    className="order-3 order-lg-4"
                    expandable
                    collapseOnEnter
                    onClick={() => {setExpanded(false);}}
                    onEnter={() => {
                        if (props.location.pathname === PATH_CATALOGUE) {
                            props.triggerSearch();
                        }
                        props.history.push(PATH_CATALOGUE);
                    }}
                />
            </Navbar>
        </div>
    </>);
}

const RouterHeader = withRouter(Header);
export default connect(mapStateToProps, mapDispatchToProps)(RouterHeader);
