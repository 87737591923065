import cn from "classnames";
import './book-stack.scss';
import coverFallback from '../../images/nocover.png';

const BookStack = ({ size = 180, className, images = [] }) => {

    return (
        <div style={{maxWidth: `calc(${size}px + 15%)`}} className={cn("book-stack", className, {"animate": images?.length > 1})}>
            <div className="inner">
                {images?.length > 0 && images.slice(0, 4).map((img, i) => {
                    if (img && img !== null) {
                        let uri = size ? img.replace('x1000.', `x${size}.`) : img;
                        return (
                            <img src={uri} alt="book cover preview" onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} key={i} />
                        );
                    }
                    return null;
                })}
                {(!images || images.length === 0) && <img src={coverFallback} alt="book cover preview" />}
            </div>
        </div>
    );
}

export default BookStack;
