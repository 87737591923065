import React from 'react';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import Loader from '../../components/Loader';
import Button from 'react-bootstrap/Button';
import GoogleAuthSetup from '../Login/components/GoogleAuthSetup';
import { request } from '../../functions/apiRequestWrapper';

import { connect } from 'react-redux';
import { fetchProfile } from '../../redux/User';
const mapStateToProps = state => {
    return {
        userData: state.user.data.actingAsUser == null ? state.user.data : state.user.data.actingAsUser,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProfile: (cb, errCb, auth) => { dispatch(fetchProfile(cb, errCb, auth)); }
    }
}

class Account2FA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: false,
            loading: false,
            step: 1,
            setupInfo: null,
            canResendEmail: true
        }
    }
    
    change2FA = () => {
      const {userData} = this.props;
      this.setState({loading: true});
      if (userData.twoFactorType === "GoogleAuthenticator") {
        this.trigger2FAEmail(userData.userId);
      }
      if (userData.twoFactorType === "Email") {
        this.triggerAppSetup(userData.userId);
      }
    }
    
    handleSubmit = (values, formikBag) => {
      const {userData} = this.props;
      this.setState({loading: true});
      let payload = {
        userId: userData.userId,
        code: values.code.trim(),
        userName: userData.userName,
        password: values.password
      };
      if (this.state.step === 2) {
        // validate email 2FA
        payload.twoFactorType = "Email";
        request(
          `${process.env.REACT_APP_API}/1/account/validateTwoFactorPin`,
          {
            method: 'POST',
            body: JSON.stringify(payload),
          }
        ).then((authData) => {
          // authentication success, load profile
          this.props.fetchProfile(
            this.props.onSubmit,
            (error) => {
              this.handleServerError(error, formikBag);
            },
            authData
          );
        }).catch((error) => {
          this.handleServerError(error, formikBag);
        });
      }
      if (this.state.step === 3) {
        // validate google 2FA setup
        payload.twoFactorSecretKey = this.state.setupInfo?.secret;
        request(
          `${process.env.REACT_APP_API}/1/account/validateTwoFactorSetup`,
          {
            method: 'POST',
            body: JSON.stringify(payload),
          }
        ).then((authData) => {
          // authentication success, load profile
          this.props.fetchProfile(
            this.props.onSubmit,
            (error) => {
              this.handleServerError(error, formikBag);
            },
            authData
          );
        }).catch((error) => {
          this.handleServerError(error, formikBag);
        });
      }
    }
    
    trigger2FAEmail = (uId, formikBag) => {
      this.setState({loading: true, canResendEmail: false});
      request(
        `${process.env.REACT_APP_API}/1/account/sendEmailVerificationCode?userId=${uId}`,
        {
          method: 'POST'
        }
      ).then(() => {
        this.setState({step: 2, loading: false, serverError: false});
        setTimeout(()=>{ this.setState({canResendEmail: true}); }, 5000);
      }).catch((error) => {
        this.handleServerError(error, formikBag);
      });
    }
    
    triggerAppSetup = (uId, formikBag) => {
      this.setState({loading: true});
      request(
        `${process.env.REACT_APP_API}/1/account/twoFactorSetup?userId=${uId}`
      ).then((res) => {
        this.setState({step: 3, loading: false, setupInfo: res, serverError: false});
      }).catch((error) => {
        this.handleServerError(error, formikBag);
      });
    }
    
    handleServerError = (error, formikBag) => {
        let msg = "An error has occured";
        if (error.body && error.body.modelState) {
          formikBag?.setErrors(error.body.modelState);
        }
        if (error.body && error.body.message) {
          msg = error.body.message;
        }
        formikBag?.setSubmitting(false);
        this.setState({serverError: msg, loading: false});
    }
    
    validateForm = (values) => {
      let errors = {};
      if (!values.code || values.code?.trim() === "") {
        errors.code = "This field is required";
      }
      if (!values.password) {
        errors.password = "This field is required";
      }
      return errors;
    }

    render() {
        const { userData } = this.props;
        return (
            <div className="position-relative">
              {this.state.loading && <Loader />}
              <h5 className="mb-2">Change 2FA method</h5>
              {this.state.step === 1 && <>
                {userData.twoFactorType === "GoogleAuthenticator" && <p>Change your 2FA method to Email. An authentication code will be sent to you registered email address.</p>}
                {userData.twoFactorType === "Email" && <p>Change your 2FA method to the Google Authenticator app.</p>}
                <div className="mt-3">
                  <Button type="button" variant="outline-primary" onClick={this.props.onCancel} className="me-2">Cancel</Button>
                  <Button type="button" variant="primary" onClick={this.change2FA}>Change 2FA to {userData.twoFactorType === "Email" ? "Google Authenticator" : "Email"}</Button>
                </div>
              </>}
              
              {this.state.step === 2 && <>
              <p>An email with an authentication code has been sent to your email address. Please enter the code, and your password, below.</p>
              <Formik validate={this.validateForm} onSubmit={this.handleSubmit} initialValues={{}}>
                {(formikBag) => (
                  <Form style={{maxWidth: "590px"}}>
                    <FormField id="code" label="2FA Code" formikBag={formikBag} />
                    <FormField id="password" label="Password" type="password" formikBag={formikBag} />
                    <p className="text-end mt-3">
                      <Button type="button" variant="outline-primary" onClick={this.props.onCancel} className="me-2">Cancel</Button>
                      <Button type="submit" variant="primary">Submit</Button>
                    </p>
                  </Form>
                )}
              </Formik>
              </>}
              
              {this.state.step === 3 && <Formik validate={this.validateForm} onSubmit={this.handleSubmit} initialValues={{}}>
                {(formikBag) => (
                  <Form style={{maxWidth: "590px"}}>
                    <GoogleAuthSetup
                      showPasswordField
                      formikBag={formikBag}
                      setupInfo={this.state.setupInfo}
                      getNewCode={()=>{ this.triggerAppSetup(userData.userId); }}
                    />
                  </Form>
                )}
              </Formik>}
              
              {this.state.serverError && <p className="server-error text-danger py-3">{this.state.serverError}</p>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account2FA);
