import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Search, ChevronRight } from 'react-bootstrap-icons';
import './search-field.scss';

import { connect } from 'react-redux';
import { setSearch, clearList } from '../../redux/Catalogue';
const mapStateToProps = state => {
    return {
        search: state.catalogue.search
    };
}
const mapDispatchToProps = dispatch => {
    return {
        setSearch: (s) => { dispatch(setSearch(s)); },
        clearList: () => { dispatch(clearList()); }
    };
}

export const SearchField = ({ search = "", setSearch = ()=>{}, clearList = ()=>{}, expandable, onClick = ()=>{}, onEnter = ()=>{}, collapseOnEnter, className }) => {

    const inputRef = useRef(null);
    const [expanded, setExpanded] = useState(!expandable);

    const expand = () => {
        if (expandable) {
            let ex = !expanded;
            setExpanded(ex);
            if (ex) {
                inputRef?.current?.focus();
            }
        }
        if (typeof onClick === "function") {
            onClick();
        }
    }

    const handleChange = (e) => {
        setSearch(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    const handleSearch = () => {
        clearList();
        if (collapseOnEnter) {
            setExpanded(false);
        }
        if (typeof onEnter === "function") {
            onEnter();
        }
    }

    return (
        <div className={classnames("search-field", className, {"expanded": expanded, "expandable": expandable})}>
            <button className="icon-wrapper" onClick={expand} aria-label="open search"><Search size={20} /></button>
            <input ref={inputRef} value={search} onChange={handleChange} onKeyDown={handleKeyDown} />
            {expanded && <button className="go" onClick={handleSearch}><ChevronRight size={28} /></button>}
        </div>
    );
}

export const CatalogueSearchField = connect(mapStateToProps, mapDispatchToProps)(
  (props) => {
    return <SearchField {...props} />
  }
);
