import { React, useRef, useEffect } from 'react';
import classnames from 'classnames';
import BookCart from '../components/BookCart';
import JsBarcode from "jsbarcode";
import { NavLink } from 'react-router-dom';
import {
    PATH_CATALOGUE,
} from '../constants';

const BookOrderDetailsItem = ({ isbn, name, amount, unitPrice, lineItems, imageUrl, className }) => {

    const ref = useRef(0);
    useEffect(() => {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, isbn, {
            format: "EAN13",
            displayValue: true,
            height: 80,
            width: 1.3
        });
        ref.current.innerHTML = "";
        ref.current.appendChild(canvas);
    }, [isbn]);

    return (
        <div className={classnames("book-list-item", className)}>
            <div className="list-item">
                <div className="row">
                    <div className="col-12 col-lg-6 cover">
                        <NavLink to={`${PATH_CATALOGUE}/${isbn}`}>
                            {imageUrl && <img src={imageUrl.replace('x1000.', 'x100.')} alt="book cover preview" className="d-block mb-2" />}
                        </NavLink>
                    </div>
                    <div className="col-12 col-lg-6 barcode">
                        <div ref={ref}></div>
                    </div>
                </div>
                <p className="book-title mb-2">{name}</p>
                <p className="mb-1">Qty: {lineItems}</p>
                <p className="mb-1">RRP: ${unitPrice}</p>
                <p className="mb-1">Subtotal: ${amount}</p>
                <p className="mt-2 mb-0"><i>Add to your current basket:</i></p>
                <BookCart isbn={isbn} />
            </div>
        </div>
    );
}

export default BookOrderDetailsItem;
