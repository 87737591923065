import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import { trimFormValues, validateEmail } from '../functions/helpers';
import Breadcrumb from '../components/Breadcrumb';
import Banner from '../components/Banner';
import Loader from '../components/Loader';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import Button from 'react-bootstrap/Button';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); }
    }
}

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {title: "Contact Us"},
            submitted: false,
            error: null
        };
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        request(
            `${process.env.REACT_APP_API}/1/cms/contentpage/contact`
        ).then((data) => {
            // console.log(data);
            this.setState({page: data});
        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            } else {
                console.error(error);
            }
        });
    }

    submitForm = (values, formikBag) => {
        let payload = trimFormValues(values);
        request(
            `${process.env.REACT_APP_API}/1/service/contactUs`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
            }
        ).then((response) => {
            console.log(response);
            formikBag.setSubmitting(false);
            this.setState({submitted: true});
        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            } else {
                console.error(error);
                let msg = "An error has occured";
                if (error.body && error.body.message) {
                    msg = error.body.message;
                }
                if (error.body && error.body.modelState) {
                    formikBag.setErrors(error.body.modelState);
                }
                this.setState({error: msg, isLoading: false});
                formikBag.setSubmitting(false);
            }
        });
    }

    validateForm = (values) => {
        let errors = {};
        if (!values.name || values.name === "") {
            errors.name = "Please enter your name";
        }
        if (!values.email || values.email === "") {
            errors.email = "Please enter your email";
        } else if (!validateEmail(values.email)) {
            errors.email = "Please enter a valid email";
        }
        if (!values.message || values.message === "") {
            errors.message = "Please enter your message";
        }
        return errors;
    }

    render() {
        const {page} = this.state;
        return (
            <div className="page contact container px-3 px-md-5">
                <Breadcrumb>{page.title}</Breadcrumb>
                <Banner imageUrl={page && page.imageUrl ? page.imageUrl : false}><h1><b>{page.title}</b></h1></Banner>
                {page && page.bodyContent && !this.state.submitted &&<>
                    <div dangerouslySetInnerHTML={{__html: page.bodyContent}} />
                    <hr className="my-5" />
                </>}

                {!this.state.submitted && <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{subject: "HarperHQ contact form submission"}}>
                    {(formikBag) => (
                        <Form className="mb-5 position-relative">
                            {formikBag.isSubmitting && <Loader />}

                            <div className="row">
                                <div className="col-md">
                                    <FormField id="name" label="Name" formikBag={formikBag} hasValidation />
                                    <FormField id="email" label="Email address" formikBag={formikBag} hasValidation />
                                </div>
                                <div className="col-md">
                                    <FormField id="message" label="Message" type="textarea" rows="4" formikBag={formikBag} />
                                </div>
                            </div>

                            {this.state.error && <p className="server-error text-danger py-3">{this.state.error}</p>}

                            <div className="text-end mt-4">
                                <Button type="submit" variant="primary" className="ms-3">Submit enquiry</Button>
                            </div>
                        </Form>
                    )}
                </Formik>}

                {this.state.submitted && <h4 className="mb-5">Thankyou for your enquiry.</h4>}
            </div>
        );
    }
}

const routerContact = withRouter(Contact);
export default connect(undefined, mapDispatchToProps)(routerContact);
