import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import { SearchField } from '../components/search/SearchField';
import Button from 'react-bootstrap/Button';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import Breadcrumb from '../components/Breadcrumb';
import coverFallback from '../nocover.png';
import { PATH_CATALOGUE } from '../constants';

import { connect } from 'react-redux';
import { getList, setSort, setSearch, clearList } from '../redux/Resources';
const mapStateToProps = state => {
    return {
        list: state.resources.list,
        count: state.resources.count,
        page: state.resources.page,
        pageSize: state.resources.pageSize,
        sortField: state.resources.sortField,
        sortDir: state.resources.sortDir,
        isLoading: state.resources.isLoading,
        error: state.resources.error,
        search: state.resources.search
    };
}
const mapDispatchToProps = dispatch => {
    return {
        getList: (p, o, s) => { dispatch(getList(p, o, s)); },
        setSort: (f, d) => { dispatch(setSort(f, d)); },
        setSearch: (s) => { dispatch(setSearch(s)); },
        clearList: () => { dispatch(clearList()); }
    };
}

const Resources = ({
  list = [], getList = ()=>{}, clearList = ()=>{},
  count, page, pageSize,
  sortField, sortDir, setSort = ()=>{},
  isLoading, error,
  search, setSearch = ()=>{}
}) => {
  
  useEffect(() => {
    setTimeout(() => {window.scrollTo(0, 0);}, 100);
    if (list?.length === 0) {
        getList();
    }
    // eslint-disable-next-line
  }, []);
  
  const handlePaginationChange = (idx) => {
      setTimeout(() => {window.scrollTo(0, 0);}, 800);
      getList(idx + 1);
  }
  
  return (
    <div className="page resources mb-5 container px-3 px-md-5">
      <Breadcrumb>Resources</Breadcrumb>
      <SearchField search={search} setSearch={setSearch} onEnter={getList} clearList={clearList} className="mb-2" />
      
      <hr className="my-5" />
      
      <div className="row mb-5">
          <div className="col-12 col-sm-auto">{count && count !== 0 ? <>Page {page} of {count} results</> : ''}</div>
          <div className="col text-sm-right sorting">
              <span className="mx-1">Sort by</span>
              <Button size="sm"
                  variant={sortField === "title" ? "primary" : "secondary"}
                  onClick={() => {setSort("title", sortDir)}}>
                  Title
              </Button>
              <Button size="sm"
                  variant={sortField === "authorName" ? "primary" : "secondary"}
                  onClick={() => {setSort("authorName", sortDir)}}>
                  Author
              </Button>
              <Button size="sm"
                  variant={sortField === "publishDate" ? "primary" : "secondary"}
                  onClick={() => {setSort("publishDate", sortDir)}}>
                  Release date
              </Button>
              <div className="divider" />
              {sortDir === "asc" && <Button size="sm"
                  variant="secondary"
                  onClick={() => {setSort(sortField, "desc")}}>
                  Ascending <ChevronDown size={16} />
              </Button>}
              {sortDir === "desc" && <Button size="sm"
                  variant="secondary"
                  onClick={() => {setSort(sortField, "asc")}}>
                  Descending <ChevronUp size={16} />
              </Button>}
          </div>
      </div>
      
      {isLoading && <Loader type={list && list.length > 0 ? "overlay" : "placeholder"} />}

      {(!isLoading && error) && <p className="alert alert-danger my-5">{error}</p>}

      {(!error && list?.length > 0) && <div className="list books">
          <div className="row">
              {list && list.map((product, i) => {
                  return (
                      <div key={product.isbn} className="col-6 pb-5">
                          <div className="resource-list-item">
                            <NavLink to={`${PATH_CATALOGUE}/${product.isbn}`} className="img">
                              {product.imageUrl &&
                                  <img src={product.imageUrl.replace('x1000.', 'x180.')} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" />
                              }
                              {!product.imageUrl &&
                                  <img src={coverFallback} alt="book cover preview" />
                              }
                            </NavLink>
                            <div className="details">
                              <div className="details-top">
                                <p className="book-title mb-2"><NavLink to={`${PATH_CATALOGUE}/${product.isbn}`}>{product.title}</NavLink></p>
                                <p>By: {product.authorName}</p>
                                <p className="mb-2">{product.resourceData?.length} Resources available:</p>
                              </div>
                              <div className="list">
                                <ul>
                                  {product.resourceData?.map((resource,i) => {
                                    return (
                                      <li key={`res${i}`}>
                                        <a href={resource.url} target="_blank" rel="noreferrer" download>{resource.title} <i>({(parseInt(resource.size) / 1000).toFixed(2)}kB)</i></a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                      </div>
                  );
              })}
          </div>
      </div>}

      {(count > pageSize) &&
          <Pagination
              totalSteps={Math.ceil(count / pageSize)}
              activeIndex={page - 1}
              onChange={handlePaginationChange}
          />
      }
      
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
