import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import GoogleAuthSetup from './GoogleAuthSetup';
import { request } from '../../../functions/apiRequestWrapper';
import { EnvelopeFill } from 'react-bootstrap-icons';
import GoogleAuthIcon from './GoogleAuthIcon';

import {
  PATH_HOME,
  PATH_FORGOT_PASS,
  FEATURE_2FA_NZ
} from '../../../constants';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      hasSentEmail: false,
      canResendEmail: true,
      setupInfo: null,
      slideIdx: 0,
      serverError: false
    }
  }
  
  handleSubmit = (values, formikBag) => {
    var payload = {};
    var s = "AU";
    if (window?.location.hostname.indexOf(".co.nz") > -1) { s = "NZ"; }
    if (this.state.step === 1) {
      payload = {username: values.username, password: values.password, source: s};
      this.login(payload, formikBag);
    }
    if (this.state.step === 2) {
      // verify 2FA
      payload = {
        userId: values.userId,
        code: values.code.trim(),
        twoFactorType: values.twoFactorType,
        userName: values.username,
        password: values.password
      };
      this.login2FA(payload, formikBag);
    }
    if (this.state.step === 4) {
      // verify google setup
      payload = {
        userId: values.userId,
        code: values.code.trim(),
        twoFactorSecretKey: this.state.setupInfo.secret,
        userName: values.username,
        password: values.password
      };
      this.validate2FASetup(payload, formikBag);
    }
  }

  login = (payload, formikBag) => {
    request(
      `${process.env.REACT_APP_API}/1/account/token`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      if (payload.source === "AU" || !FEATURE_2FA_NZ) {
        if (!authData?.accessToken) {
          this.handleServerError("Unable to authenticate", formikBag);
        } else {
          // authentication success, load profile
          this.props.fetchProfile(
            this.props.onLoadProfile,
            (error) => {
              this.handleServerError(error, formikBag);
            },
            authData
          );
        }
      } else {
        // 2FA
        if (this.state.serverError) { this.setState({serverError: false}); }
        formikBag.setValues({...payload, ...authData});
        formikBag.setSubmitting(false);
        if (authData.twoFactorEnabled) {
          this.setState({step: 2});
          if (authData.twoFactorType === "Email") {
            this.trigger2FAEmail(authData.userId, formikBag);
          }
        } else {
          this.setState({step: 3});
        }
      }
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  login2FA = (payload, formikBag) => {
    request(
      `${process.env.REACT_APP_API}/1/account/validateTwoFactorPin`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      if (!authData?.accessToken) {
        this.handleServerError("Unable to authenticate", formikBag);
      } else {
        // authentication success, load profile
        this.props.fetchProfile(
          this.props.onLoadProfile,
          (error) => {
            this.handleServerError(error, formikBag);
          },
          authData
        );
      }
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  validate2FASetup = (payload, formikBag) => {
    request(
      `${process.env.REACT_APP_API}/1/account/validateTwoFactorSetup`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      if (!authData?.accessToken) {
        this.handleServerError("Unable to authenticate", formikBag);
      } else {
        // authentication success, load profile
        this.props.fetchProfile(
          this.props.onLoadProfile,
          (error) => {
            this.handleServerError(error, formikBag);
          },
          authData
        );
      }
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  trigger2FAEmail = (uId, formikBag) => {
    this.setState({loading: true, canResendEmail: false});
    request(
      `${process.env.REACT_APP_API}/1/account/sendEmailVerificationCode?userId=${uId}`,
      {
        method: 'POST'
      }
    ).then(() => {
      this.setState({step: 2, loading: false, serverError: false});
      setTimeout(()=>{ this.setState({canResendEmail: true}); }, 5000);
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  triggerAppSetup = (uId, formikBag) => {
    this.setState({loading: true});
    request(
      `${process.env.REACT_APP_API}/1/account/twoFactorSetup?userId=${uId}`
    ).then((res) => {
      this.setState({step: 4, loading: false, setupInfo: res, serverError: false});
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }

  handleServerError = (error, formikBag) => {
      console.log("server error");
      let msg = "An error has occured";
      if (typeof error === "string") {
        msg = error;
      } else if (error.body && error.body.modelState) {
        formikBag?.setErrors(error.body.modelState);
      }
      if (error.body && error.body.message) {
        msg = error.body.message;
      }
      formikBag?.setSubmitting(false);
      this.setState({serverError: msg, canResendEmail: true, loading: false});
  }

  validateForm = (values) => {
    let errors = {};
    if (this.state.step === 1) {
      if (!values.username) { errors.username = "This field is required"; }
      if (!values.password) { errors.password = "This field is required"; }
    }
    if (this.state.step === 2 || this.state.step === 4) {
      if (!values.code) { errors.code = "This field is required"; }
    }
    return errors;
  }

  render() {
    const { step } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.handleSubmit} initialValues={this.props.initialValues}>
        {(formikBag) => (
          <Form className="p-5 position-relative">

            {(formikBag.isSubmitting || this.state.loading) && <Loader />}

            <h3 className="mb-4">Login to your account</h3>
            
            {step === 1 && <>
              <FormField id="username" label="Email address or Customer ID" formikBag={formikBag} onChange={this.props.onChangeUsername} />
              <FormField id="password" type="password" label="Password" formikBag={formikBag} />
              <p className="">
                <Link to={{pathname: PATH_FORGOT_PASS}}>Forgot your password?</Link>
              </p>
              <div className="text-end mt-4">
                <Button type="submit" variant="primary">Login</Button>
              </div>
            </>}
            
            {step === 2 && <>
              {formikBag.values.twoFactorType === "Email" && <p>A verification code has been sent to your registered email address. Please enter it below.</p>}
              {formikBag.values.twoFactorType === "GoogleAuthenticator" && <p>Enter the 6-digit code from your Google Authenticator app.</p>}
              <FormField id="code" label="2FA Code" formikBag={formikBag} />
              {formikBag.values.twoFactorType === "Email" && <p>
                <Button type="button" variant="link" className="px-0" onClick={()=>{ this.trigger2FAEmail(formikBag.values.userId); }} disabled={!this.state.canResendEmail}>Resend email verification code</Button>
              </p>}
              <div className="text-end mt-4">
                <Button type="submit" variant="primary">Verify</Button>
              </div>
            </>}
            
            {step === 3 && <>
              <h5>You need to enable 2FA</h5>
              <p>In order to make your experience with us as safe as possible, you will need to use <b>Two-Factor Authentication (2FA)</b> to log in.</p>
              <p>You can either have a verification code sent to your email, or use the Google Authenticator app. Please make a selection below.</p>
              <p>
                <Button type="button" variant="primary" className="me-3" onClick={()=>{
                  formikBag.setFieldValue("twoFactorType", "Email");
                  this.trigger2FAEmail(formikBag.values.userId);
                }}><EnvelopeFill size={16} className="mt-n1 me-1" /> Use Email</Button>
                <Button type="button" variant="outline-primary" onClick={()=>{
                  formikBag.setFieldValue("twoFactorType", "GoogleAuthenticator");
                  this.triggerAppSetup(formikBag.values.userId);
                }}><GoogleAuthIcon style={{width:"1.2em", height: "1.2em"}} /> Use Google Authenticator App</Button>
              </p>
            </>}
            
            {step === 4 && <GoogleAuthSetup formikBag={formikBag} setupInfo={this.state.setupInfo} getNewCode={()=>{ this.triggerAppSetup(formikBag.values.userId); }} />}

            {this.state.serverError && <p className="server-error text-danger py-3">{this.state.serverError}</p>}
          </Form>
        )}
      </Formik>
    );
  }
}

// LoginForm.propTypes = {
//   onChangeUsername: PropTypes.func,
//   initialValues: PropTypes.object,
//   initialStep: PropTypes.number,
//   goTo: PropTypes.func,
//   fetchProfile: PropTypes.func,
//   onLoadProfile: PropTypes.func,
//   from: PropTypes.any
// }

LoginForm.defaultProps = {
  from: { pathname: PATH_HOME }
}

export default LoginForm;
