import React from 'react';
import cn from 'classnames';
import Spinner from 'react-bootstrap/Spinner';

import './loader.scss';

const Loader = ({ type = "overlay", bgColor = "white", className, spinnerColor = "dark", children }) => {
  return (
    <div className={cn("loader", type, bgColor, className)}>
      <div className="loader__inner">
        <Spinner animation="border" role="status" variant={spinnerColor} />
        {children && <div>{children}</div>}
      </div>
    </div>
  );
}

export default Loader;
