import { useState } from "react";
import { ArrowLeftShort, ArrowRightShort } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import appBtn from './google_auth_app_button.png';
import FormField from '../../../components/FormField';
import cn from 'classnames';

const GoogleAuthSetup = ({ formikBag, setupInfo, getNewCode, showPasswordField }) => {
  
  const [slideIdx, setSlideIdx] = useState(0);
  
  return (<>
    <h5>Google Authenticator setup</h5>
    <div className="d-flex justify-content-between align-items-center mb-3 bg-light rounded-pill">
      <Button type="button" variant="link" className={cn("p-0", {"invisible": slideIdx === 0})} onClick={()=>{ setSlideIdx(slideIdx - 1); formikBag.setErrors({}); }} disabled={slideIdx === 0}><ArrowLeftShort size="2em" /><span className="visually-hidden">previous</span></Button>
      <span><b>{slideIdx + 1}</b>/4</span>
      <Button type="button" variant="primary" className={cn({"invisible": slideIdx === 3})} onClick={()=>{ setSlideIdx(slideIdx + 1); }} disabled={slideIdx === 3}>Next <ArrowRightShort size="1.3em" className="mt-n1" /></Button>
    </div>
    <Carousel interval={null} activeIndex={slideIdx} onSelect={setSlideIdx} controls={false} indicators={false} wrap={false}>
      <Carousel.Item style={{minHeight: "165px"}}>
        <p>Download the <b>Google Authenticator</b> app to your phone.</p>
        <p>Google Authenticator is available for <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US" target="_blank" rel="noreferrer">Android</a>.</p>
      </Carousel.Item>
      <Carousel.Item style={{minHeight: "165px"}}>
        <p>Open the app and tap the "Get started" button. You will have the option to either log in with your Google account or use the app without logging in. If you do log in, Google Authenticator will save your 2FA data for you.</p>
        <p>To add an account to the app, tap the <img src={appBtn} alt="+" style={{width: "1.5em", height: "1.5em"}} /> button in the bottom-right. Select the option to Scan a QR Code using your phone, or if you are reading this on your phone select the option to Enter a Setup Key.</p>
      </Carousel.Item>
      <Carousel.Item>
        <p>Scan the below QR Code: <br /><img src={setupInfo?.qrCodeSetupImageUrl} alt="QR Code" className="my-4" />
        <br />or use the following setup key<br /> <b style={{wordWrap: "anywhere"}}>{setupInfo?.manualEntryKey}</b></p>
        {typeof getNewCode === "function" && <p><Button type="button" variant="link" className="px-0" onClick={getNewCode}>Get a new code</Button></p>}
      </Carousel.Item>
      <Carousel.Item>
        <p>Google Authenticator will generate a 6-digit code, enter the code{showPasswordField && " and your password"} below and click Verify</p>
        <FormField id="code" label="2FA Setup Code" formikBag={formikBag} />
        {showPasswordField && <FormField id="password" label="Password" type="password" formikBag={formikBag} />}
        <div className="text-end mt-3">
          <Button type="submit" variant="primary">Verify</Button>
        </div>
      </Carousel.Item>
    </Carousel>
  </>);
};

export default GoogleAuthSetup;
