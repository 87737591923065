import { useState, useEffect, useRef } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import coverFallback from '../../images/nocover.png';
import { request } from '../../functions/apiRequestWrapper';

const InternalPreview = ({ images, buttonText }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const timeoutRef = useRef(null);

  const onFirstImgLoad = () => {
    console.log("image loaded");
    setLoading(false);
    setFirstLoaded(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }
  const onFirstImgError = () => {
    console.log("image error");
    setHasError(true);
    setLoading(false);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }
  const onImgTimeout = () => {
    console.log("image timeout");
    if (!firstLoaded) {
      setHasError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    timeoutRef.current = setTimeout(onImgTimeout, 20000);
    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <button type="button" className="btn btn-primary me-2 mb-2" onClick={()=>{ setOpen(true); }} style={loading || hasError ? {display: "none"} : {}}>
        {buttonText}
    </button>
    {images?.length > 0 && <div style={{width:"1px", height:"1px", overflow:"hidden"}}>
      <img src={images[0]} alt="" onLoad={onFirstImgLoad} onError={onFirstImgError} />
    </div>}
    <Lightbox
      open={open}
      close={()=>{ setOpen(false); }}
      slides={images.map((img,i) => {
        return {src: img, alt: ""}
      })}
      index={open}
      carousel={{finite: true}}
      plugins={[Thumbnails, Counter, Zoom]}
    />
    </>
  );
}

const CoverPreview = ({ imageUrl }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
    <img src={imageUrl} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" onClick={()=>{ setOpen(true); }} />
    <Lightbox
      open={open}
      close={()=>{ setOpen(false); }}
      slides={[
        {src: imageUrl, alt: "Cover"}
      ]}
      carousel={{finite: true}}
      plugins={[Zoom]}
    />
    </>
  );
}

const CombinedPreview = ({ imageUrl, images, onClickVideo }) => {
  const [open, setOpen] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const timeoutRef = useRef(null);
  
  const onFirstImgLoad = () => {
    console.log("image loaded");
    setLoading(false);
    setFirstLoaded(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }
  const onFirstImgError = () => {
    console.log("image error");
    setHasError(true);
    setLoading(false);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }
  const onImgTimeout = () => {
    console.log("image timeout");
    if (!firstLoaded) {
      setHasError(true);
    }
    setLoading(false);
  }
  
  useEffect(() => {
    timeoutRef.current = setTimeout(onImgTimeout, 20000);
    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
    {!imageUrl &&
      <img src={coverFallback} alt="book cover preview" />
    }
    {imageUrl &&
      <img src={imageUrl} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" onClick={()=>{ setOpen(0); }} />
    }
    <div className="mt-2">
      <button type="button" className="btn btn-primary me-2 mb-2" onClick={() => { let idx = imageUrl ? 1 : 0; setOpen(idx); }} style={loading || hasError ? {display: "none"} : {}}>
          View Inside
      </button>
      {(typeof onClickVideo === 'function') &&
        <button type="button" className="btn btn-primary me-2 mb-2" onClick={(e) => { e.preventDefault(); onClickVideo(); }}>Video</button>
      }
    </div>
    {images.length > 0 && <div style={{width:"1px", height:"1px", overflow:"hidden"}}>
      <img src={images[0]} alt="" onLoad={onFirstImgLoad} onError={onFirstImgError} />
    </div>}
    <Lightbox
      open={open > -1}
      close={()=>{ setOpen(-1); }}
      slides={[
        {src: imageUrl, alt: "Cover"},
        ...images.map((img,i) => {
          return {src: img, alt: ""}
        })
      ]}
      index={open}
      carousel={{finite: true}}
      plugins={[Thumbnails, Counter, Zoom]}
    />
    </>
  )
}

const BookCoverWithPreview = ({ isbn, isOffline, imageUrl, onClickVideo, ss }) => {
  
  const [images, setImages] = useState([]);
  const [marketing, setMarketing] = useState([]);
  
  useEffect(() => {
    if (!isOffline) {
      request(
        `${ss ? process.env.REACT_APP_API_SS : process.env.REACT_APP_API}/1/service/isbn/${isbn}?folderName=internal_images`
      ).then((data) => {
        // console.log("internal", data);
        setImages(data);
      }).catch((error) => {
        // console.error(error);
      });
      if (ss) {
        request(
          `${ss ? process.env.REACT_APP_API_SS : process.env.REACT_APP_API}/1/service/isbn/${isbn}?folderName=marketing`
        ).then((data) => {
          // console.log("marketing", data);
          setMarketing(data);
        }).catch((error) => {
          // console.error(error);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (ss) {
    return (
      <div className="pt-5 pt-md-0 book-cover">
        <div className="book-cover-wrapper">
          {imageUrl &&
            <CoverPreview imageUrl={imageUrl} />
          }
          {!imageUrl &&
            <img src={coverFallback} alt="book cover preview" />
          }
          <div className="mt-2">
            {(images?.length > 0) &&
              <InternalPreview images={images} buttonText="View Inside" />
            }
            {(marketing?.length > 0) &&
              <InternalPreview images={marketing} buttonText="Marketing" />
            }
            {(typeof onClickVideo === 'function') &&
              <button type="button" className="btn btn-primary me-2 mb-2" onClick={(e) => { e.preventDefault(); onClickVideo(); }}>Video</button>
            }
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="pt-5 pt-md-0 book-cover">
      <div className="book-cover-wrapper">
        <CombinedPreview imageUrl={imageUrl} images={images} onClickVideo={onClickVideo} />
      </div>
    </div>
  );
}

export default BookCoverWithPreview;
